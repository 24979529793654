import React from "react";
import "../pagesCss/AboutFounder.css";
import { Helmet } from "react-helmet-async"; // Use react-helmet-async for SEO

const AboutFounder = () => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Person",
            name: "Deepak Sagar",
            url: "https://holyvrindavantour.net/",
            image: "https://holyvrindavantour.net/images/holyfounder.jpeg",
            sameAs: [
              "https://www.facebook.com/share/MUvfFFkzAwRipJSx/",
              "https://www.instagram.com/deepaksagar1999?igsh=MTZ2NHg4YXdzZGw2YQ=="
            ],
            jobTitle: "Founder",
            worksFor: {
              "@type": "Organization",
              name: "Holy Vrindavan Tour"
            }
          })}
        </script>
      </Helmet>

      <div className="about-section mt-5 bg-light">
        <div className="content-container pt-4">
          <div className="logo-container text-center">
            <h2 className="fw-bold fs-1 text-dark">
              Meet Our <span className="text main-hover">Founder</span>
            </h2>
            <div className="row about-s pt-5">
              <div
                className="col-lg-4 col-md-12 logo-column"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <img
                  src="/images/holyfounder.jpeg"
                  alt="Deepak Sagar - Founder"
                  className="logo-img"
                />
                <div className="founder">
                  <h2 className="text-dark">
                    <strong>Deepak Sagar</strong>
                  </h2>
                  <span className="text-dark">(CEO & Founder)</span>
                </div>
              </div>

              <div
                className="col-lg-8 col-md-12 text-column"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <p className="text-dark text-justify text-p">
                  At the heart of Holy Vrindavan Tour lies the vision and passion
                  of our founder, Deepak Sagar. With a profound love for travel
                  and a keen entrepreneurial spirit, Deepak embarked on a journey
                  to redefine the way people experience the world.
                  <br />
                  <br />
                  Deepak's journey began with a fascination for exploring new
                  cultures, discovering hidden gems, and forging meaningful
                  connections with people around the globe. This personal passion
                  evolved into a mission to curate exceptional travel experiences
                  that showcase destinations and immerse travelers in authentic
                  local experiences.
                  <br />
                  <br />
                  With over 5+ years of experience in the travel industry, Deepak
                  brings a wealth of knowledge and expertise to Holy Vrindavan Tour.
                  Starting as a tourist guide, he gained insights into the nuances
                  of travel planning, customer preferences, and operational logistics.
                  <br />
                  <br />
                  Whether it’s crafting bespoke luxury journeys or promoting
                  sustainable eco-tourism, Deepak ensures every trip exceeds expectations.
                  Through partnerships with top hotels, airlines, local guides, and
                  service providers worldwide, we offer unparalleled access and exclusive perks.
                  <br />
                  <br />
                  Ultimately, Deepak is not just a leader but a visionary who inspires our
                  team to uphold the highest standards of service and integrity. His dedication
                  has earned Holy Vrindavan Tour a reputation for excellence and a loyal clientele.
                  <br />
                  <br />
                  Join us on a journey curated by Deepak, where every destination becomes
                  a story waiting to be cherished.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutFounder;
